import { createRouter, createWebHashHistory } from 'vue-router'
import HomePage from '../views/HomePage.vue'
import ResultsPage from '../views/ResultsPage.vue'
import ConfirmedPage from '../views/ConfirmedPage.vue'
import IPhoneGuidePage from '../views/IPhoneGuidePage.vue'
import AndroidGuidePage from '../views/AndroidGuidePage.vue'

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/results/:uuid',
    name: 'ResultsPage',
    component: ResultsPage
  },
  {
    path: '/confirmed/',
    name: 'ConfirmedPage',
    component: ConfirmedPage
  },
  {
    path: '/iphone/',
    name: 'IPhoneGuidePage',
    component: IPhoneGuidePage
  },
  {
    path: '/android/',
    name: 'AndroidGuidePage',
    component: AndroidGuidePage
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
