export default {
  "upload": {
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred uploading the file! Try again!"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a file to upload and check"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please share your name and email address with us:"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])}
  },
  "result": {
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error!"])},
    "error_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately the upload or the analysis failed. Please try to re-upload!"])},
    "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go back"])},
    "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning!"])},
    "infection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I found traces of infection!"])},
    "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File:"])},
    "analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysis identifier:"])},
    "platform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform:"])},
    "detections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detections:"])},
    "indicator": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Malicious ", _interpolate(_list(0)), " related to ", _interpolate(_list(1)), " on ", _interpolate(_list(2))])},
    "question": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["For questions or further support you should contact ", _interpolate(_list(0)), ". Keep note of the ", _interpolate(_list(1)), ", for example by taking a screenshot."])},
    "identifier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["analysis identifier"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK!"])},
    "no_trace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No known traces found."])},
    "warning_traces": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " There might be previously unknown or ambiguous traces. For further analysis you should contact ", _interpolate(_list(1)), ". Keep note of the ", _interpolate(_list(2)), ", for example by taking a screenshot."])}
  },
  "waiting": {
    "waiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for results."])},
    "holdon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please hold on, it should only take a minute."])}
  },
  "confirmed": {
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload successful!"])},
    "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We successfully received your upload. We will get back to you as soon as possible."])}
  },
  "iphone": {
    "iphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iPhone"])},
    "intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The process to analyse an iPhone (or any other iOS) device involves the generation of an archive containing diagnostic logs from the device. Although they might contain some identifiers (such as the IMEI of the device), these logs ", _interpolate(_list(0)), " such as messages, pictures, or emails, but only crash logs and other system information."])},
    "donot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["do not contain private data"])},
    "intro2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this page you will be guided through the steps of generating these diagnostic logs and uploading them to the platform."])},
    "step1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Firstly open ", _interpolate(_list(0)), " and navigate to ", _interpolate(_list(1)), ", then find the menu ", _interpolate(_list(2)), ":"])},
    "step2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["In ", _interpolate(_list(0)), " navigate to ", _interpolate(_list(1)), ", toggle the option on:"])},
    "step3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Now a gray circle should have appeared on your screen. Under ", _interpolate(_list(0)), " select ", _interpolate(_list(1)), ":"])},
    "step4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["If you now double-tap on the circle you should launch the gathering of diagnostic logs. This should be notified by a bar on top saying ", _interpolate(_list(0)), " like in the picture:"])},
    "step5": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["You can now toggle ", _interpolate(_list(0)), " off, as it is no longer needed."])},
    "step6": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The process of gathering diagnostic logs will take several minutes. Wait at least 3-4 minutes. To check the progress go back to ", _interpolate(_list(0)), ", then into ", _interpolate(_list(1)), ". Now scroll to the bottom to ", _interpolate(_list(2)), ", then find ", _interpolate(_list(3)), "."])},
    "step7": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Now you will probably see a lot of entries ordered alphabetically. Nothing to worry about, it is normal. If you scroll towards the letter ", _interpolate(_list(0)), " and you see an entries starting with name ", _interpolate(_list(1)), " it means that the gathering of diagnostic logs is undergoing. Wait a few minutes more and try again."])},
    "step8": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["If you then don't see an entry starting with name ", _interpolate(_list(0)), ", scroll to the letter ", _interpolate(_list(1)), ", and if you see an entry starting with ", _interpolate(_list(2)), " it means that the gathering of diagnostic logs completed successfully. You might have multiple entries there. You should pick the most recent one."])},
    "step9": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["If you now click on the most recent ", _interpolate(_list(0)), " entry, you can save it using standard iOS button located on the top-right. Then select ", _interpolate(_list(1)), ", and select ", _interpolate(_list(2)), " and lastly the ", _interpolate(_list(3)), " folder. You are now ready to upload the diagnostic logs for analysis."])},
    "step10": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["You can now navigate to the ", _interpolate(_list(0)), ", select the file, insert your email address, and upload."])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "accessibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessibility"])},
    "touch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Touch"])},
    "assistivetouch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistive Touch"])},
    "analytics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analytics"])},
    "gathering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gathering Analytics"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy"])},
    "analyticsimp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analytics & Improvements"])},
    "analyticsdata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analytics Data"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save to Files"])},
    "onmyiphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On My iPhone"])},
    "downloads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downloads"])},
    "doubletap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Double-Tap"])}
  }
}