export default {
  "upload": {
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Ocurrió un error al cargar el archivo! ¡Intentar otra vez!"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione un archivo para cargar y verifique"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, comparta su nombre y dirección de correo electrónico con nosotros:"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subir"])}
  },
  "result": {
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Error!"])},
    "error_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desafortunadamente, la carga o el análisis fallaron. Por favor, intente volver a cargar!"])},
    "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regresa"])},
    "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Advertencia!"])},
    "infection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Encontré rastros de infección!"])},
    "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivo:"])},
    "analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificador de análisis:"])},
    "platform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plataforma:"])},
    "detections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detecciones:"])},
    "indicator": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " malicioso relacionado con ", _interpolate(_list(1)), " en ", _interpolate(_list(2))])},
    "question": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Si tiene preguntas o necesita más ayuda, debe ponerse en contacto con ", _interpolate(_list(0)), ". Tome nota del ", _interpolate(_list(1)), ", por ejemplo, tomando una captura de pantalla."])},
    "identifier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["identificador de análisis"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡DE ACUERDO!"])},
    "no_trace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron rastros conocidos."])},
    "warning_traces": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " Es posible que haya rastros previamente desconocidos o ambiguos. Para un análisis más detallado, debe comunicarse con ", _interpolate(_list(1)), ". Tome nota del ", _interpolate(_list(2)), ", por ejemplo, tomando una captura de pantalla."])}
  },
  "waiting": {
    "waiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esperando resultados."])},
    "holdon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, espere, solo debería tomar un minuto."])}
  },
  "confirmed": {
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Subida exitosa!"])},
    "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibimos correctamente su carga. Nos pondremos en contacto con usted lo antes posible."])}
  },
  "iphone": {
    "iphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iPhone"])},
    "intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["El proceso para analizar un dispositivo iPhone (o cualquier otro iOS) implica la generación de un archivo que contiene registros de diagnóstico del dispositivo. Aunque pueden contener algunos identificadores (como el IMEI del dispositivo), estos registros ", _interpolate(_list(0)), " como mensajes, imágenes o correos electrónicos, pero solo registros de fallas y otra información del sistema."])},
    "donot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no contienen datos privados"])},
    "intro2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En esta página, se le guiará a través de los pasos para generar estos registros de diagnóstico y cargarlos en la plataforma. "])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenga en cuenta:"])},
    "step1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Primero abra ", _interpolate(_list(0)), " y navegue hasta ", _interpolate(_list(1)), ", luego busque el menú ", _interpolate(_list(2)), ":"])},
    "step2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["En ", _interpolate(_list(0)), " navegue a ", _interpolate(_list(1)), ", active la opción:"])},
    "step3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ahora debería haber aparecido un círculo gris en su pantalla. En ", _interpolate(_list(0)), " seleccione ", _interpolate(_list(1)), ":"])},
    "step4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Si ahora toca dos veces el círculo, debería iniciar la recopilación de registros de diagnóstico. Esto debería ser notificado por una barra en la parte superior que dice ", _interpolate(_list(0)), " como en la imagen:"])},
    "step5": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ahora puede desactivar ", _interpolate(_list(0)), ", ya que ya no es necesario."])},
    "step6": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["El proceso de recopilación de registros de diagnóstico llevará varios minutos. Espere al menos 3-4 minutos. Para comprobar el progreso, vuelva a ", _interpolate(_list(0)), " y luego a ", _interpolate(_list(1)), ". Ahora desplácese hacia abajo hasta ", _interpolate(_list(2)), ", luego busque ", _interpolate(_list(3)), "."])},
    "step7": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ahora probablemente verá muchas entradas ordenadas alfabéticamente. No hay de qué preocuparse, es normal. Si se desplaza hacia la letra ", _interpolate(_list(0)), " y ve entradas que comienzan con el nombre ", _interpolate(_list(1)), ", significa que se están recopilando registros de diagnóstico. Espere unos minutos más y vuelva a intentarlo."])},
    "step8": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Si luego no ve una entrada que comience con el nombre ", _interpolate(_list(0)), ", desplácese hasta la letra ", _interpolate(_list(1)), ", y si ve una entrada que comienza con ", _interpolate(_list(2)), ", significa que la recopilación de registros de diagnóstico se completó correctamente. Es posible que tenga varias entradas allí. Debe elegir el más reciente."])},
    "step9": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Si ahora hace clic en la entrada ", _interpolate(_list(0)), " más reciente, puede guardarla usando el botón estándar de iOS ubicado en la parte superior derecha. Luego seleccione ", _interpolate(_list(1)), ", y seleccione ", _interpolate(_list(2)), " y, por último, la carpeta ", _interpolate(_list(3)), ". Ahora está listo para cargar los registros de diagnóstico para su análisis."])},
    "step10": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ahora puede navegar hasta ", _interpolate(_list(0)), ", seleccionar el archivo, insertar su dirección de correo electrónico y cargarlo."])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajustes"])},
    "accessibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accesibilidad"])},
    "touch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toque"])},
    "assistivetouch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toque de asistencia"])},
    "analytics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analítica"])},
    "gathering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recopilación de análisis"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacidad"])},
    "analyticsimp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis y mejoras"])},
    "analyticsdata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos analíticos"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar en archivos"])},
    "onmyiphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en mi iPhone"])},
    "downloads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargas"])},
    "doubletap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doble toque"])}
  }
}