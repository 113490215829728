export default {
  "upload": {
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite lors de l'envoi du fichier ! Merci de réessayer."])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez un fichier à envoyer et analyser"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci de partager votre nom et adresse email :"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoi"])}
  },
  "result": {
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur !"])},
    "error_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malheureusement l'envoi et l'analyse ont échoué. Merci de réessayer !"])},
    "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
    "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention !"])},
    "infection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons trouvé des traces d'infection"])},
    "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichier :"])},
    "analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant d'analyse :"])},
    "platform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plateforme :"])},
    "detections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détections :"])},
    "indicator": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " malveillant en rapport avec ", _interpolate(_list(1)), " le ", _interpolate(_list(2))])},
    "question": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Pour toute question ou support plus poussé, veuillez contacter ", _interpolate(_list(0)), ". Notez le ", _interpolate(_list(1)), ", par exemple en prenant une capture d'écran."])},
    "identifier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["identifiant d'analyse"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK !"])},
    "no_trace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune trace trouvée."])},
    "warning_traces": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " Il peut y avoir des traces ambigues ou inconnues jusqu'à présent. Pour une analyse plus détailler, vous devriez contacter ", _interpolate(_list(1)), ". Notez le ", _interpolate(_list(2)), ", par exemple en prenant une capture d'écran."])}
  },
  "waiting": {
    "waiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente des résultats."])},
    "holdon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez patienter, cela ne devrait prendre qu'une minute."])}
  },
  "confirmed": {
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoi réussi !"])},
    "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons bien reçu votre fichier. Nous reviendrons vers vous dès que possible."])}
  },
  "iphone": {
    "iphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iPhone"])},
    "intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["L'analyser d'un iPhone (ou d'un autre appareil sous iOS) demande de générer une archive contenant des logs de diagnostic de l'appareil. Même si elle peut contenir certains identifiants (comme le code IMEI du téléphone), ces logs ", _interpolate(_list(0)), " comme des messages, des photos ou des emails, mais seulement des informations sur les crashs et d'autres informations sur le système."])},
    "donot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ne contient pas de données personnelles"])},
    "intro2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sur cette page, vous serez guidé(e) à travers les différentes étapes pour générer ces logs de diagnostic et les envoyer à cette plateforme. "])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez noter : "])},
    "step1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Tout d'abord, ouvrez ", _interpolate(_list(0)), " et allez dans ", _interpolate(_list(1)), ", ensuite cherchez le menu ", _interpolate(_list(2)), " :"])},
    "step2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Dans ", _interpolate(_list(0)), " allez dans ", _interpolate(_list(1)), ", et activez cette option : "])},
    "step3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Un cercle gris doit maintenant apparaître sur l'écran. Dans ", _interpolate(_list(0)), ", sélectionez ", _interpolate(_list(1)), " : "])},
    "step4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Si vous touchez deux fois le cercle, vous devriez lancer la génération des logs de diagnostic. Cela devrait vous être notifié par une barre en haut de l'écran indiquant ", _interpolate(_list(0)), " comme sur l'image suivante : "])},
    "step5": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Vous pouvez maintenant désactiver ", _interpolate(_list(0)), ", qui n'est plus utile."])},
    "step6": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["La génération des logs de diagnostic devrait prendre plusieurs minutes. Attendez au moins 3 ou 4 minutes. Pour vérifier son avancement, retournez dans ", _interpolate(_list(0)), ", puis dans ", _interpolate(_list(1)), ". Faites défiler vers le bas jusqu'à ", _interpolate(_list(2)), ", et trouvez ", _interpolate(_list(3)), "."])},
    "step7": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Maintenant, vous devez probablement voir une longue liste de fichiers triées par ordre alphabétique. Rien à craindre, c'est tout à fait normal. Si vous faites défiler vers le bas jusqu'à la lettre ", _interpolate(_list(0)), " et que vous voyez un fichier commençant par ", _interpolate(_list(1)), ", cela veut dire que la génération est encore en cours. Attendez quelques minutes de plus et revérifiez."])},
    "step8": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Si vous ne voyez pas de fichier commençant avec le nom ", _interpolate(_list(0)), ", faites défiler jusqu'à la lettre ", _interpolate(_list(1)), ". Si vous voyez un fichier commençant par ", _interpolate(_list(2)), ", cela veut dire que les logs de diagnostic ont été correctement générés. Vous pouvez avoir plusieurs fichiers de diagnostic. Vous devez utiliser le plus récent."])},
    "step9": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Si vous cliquez sur l'entrée ", _interpolate(_list(0)), " la plus récente, vous pouvez le sauvegarder en utilisant le bouton iOS standard situé au haut à droite de l'écran. Sélectionnez ensuite ", _interpolate(_list(1)), ", puis ", _interpolate(_list(2)), " et enfin le dossier ", _interpolate(_list(3)), ". Vous pouvez maintenant envoyer les logs de diagnostics pour analyse.\n"])},
    "step10": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Vous ouvez maintenant naviguer dans les ", _interpolate(_list(0)), ", sélectionner le fichier, entrer votre adresse email et envoyer."])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres"])},
    "accessibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessibilité"])},
    "touch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toucher"])},
    "assistivetouch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AssistiveTouch"])},
    "analytics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse"])},
    "gathering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collecte des analyses"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confidentialité"])},
    "analyticsimp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse et améliorations"])},
    "analyticsdata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données d'analyse"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer dans Fichiers"])},
    "onmyiphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sur mon iPhone"])},
    "downloads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargements"])},
    "doubletap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Double toucher"])}
  }
}