export default {
  "upload": {
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدث خطأ أثناء تحميل الملف! حاول مرة أخري!"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدد ملفًا لتحميله والتحقق منه"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى مشاركة عنوان بريدك الإلكتروني معنا:"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل"])}
  },
  "result": {
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ!"])},
    "error_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للأسف فشل التحميل أو التحليل. يرجى محاولة إعادة التحميل!"])},
    "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عُد"])},
    "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحذير!"])},
    "infection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد وجدت آثارا للعدوى!"])},
    "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملف:"])},
    "analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معرّف التحليل:"])},
    "platform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منصّة:"])},
    "detections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاكتشافات:"])},
    "indicator": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["ضار ", _interpolate(_list(0)), " متعلق بـ ", _interpolate(_list(1)), " على ", _interpolate(_list(2))])},
    "question": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["للأسئلة أو لمزيد من الدعم ، يجب الاتصال بـ ", _interpolate(_list(0)), ". احتفظ بالمعلومة التالية ", _interpolate(_list(1)), " ، على سبيل المثال من خلال أخذ لقطة شاشة."])},
    "identifier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معرّف التحليل"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نعم!"])},
    "no_trace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم العثور على آثار معروفة."])},
    "warning_traces": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " قد تكون هناك آثار غير معروفة أو غامضة من قبل. لمزيد من التحليل ، يجب عليك الاتصال بـ ", _interpolate(_list(1)), ". احتفظ بالمعلومة التالية ", _interpolate(_list(2)), " ، على سبيل المثال من خلال أخذ لقطة شاشة."])}
  },
  "waiting": {
    "waiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في انتظار النتائج."])},
    "holdon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى الانتظار ، من المفروض أن يستغرق الأمر دقيقة واحدة فقط."])}
  },
  "confirmed": {
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل ناجح!"])},
    "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد تلقينا تحميلك بنجاح. سنعود إليك في أقرب وقت ممكن."])}
  },
  "iphone": {
    "iphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ايفون"])},
    "intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["تتضمن عملية تحليل جهاز iPhone (أو أي جهاز iOS آخر) إنشاء أرشيف يحتوي على سجلات التشخيص من الجهاز. على الرغم من أنها قد تحتوي على بعض المعرفات (مثل IMEI للجهاز) ، إلا أن هذه السجلات ", _interpolate(_list(0)), " مثل الرسائل أو الصور أو رسائل البريد الإلكتروني ، ولكن فقط سجلات الأعطال ومعلومات النظام الأخرى."])},
    "donot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا تحتوي على بيانات خاصة"])},
    "intro2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في هذه الصفحة سيتم إرشادك خلال خطوات إنشاء سجلات التشخيص هذه وتحميلها على المنصّة."])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى الملاحظة:"])},
    "step1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["أولاً افتح ", _interpolate(_list(0)), " وانتقل إلى ", _interpolate(_list(1)), " ، ثم ابحث عن القائمة ", _interpolate(_list(2)), ":"])},
    "step2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["في ", _interpolate(_list(0)), " انتقل إلى ", _interpolate(_list(1)), " ، بدّل الخيار إلى تشغيل:"])},
    "step3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["الآن يجب أن تظهر دائرة رمادية على شاشتك. ضمن ", _interpolate(_list(0)), " قم باختيار ", _interpolate(_list(1)), ":"])},
    "step4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["إذا نقرت الآن نقرًا مزدوجًا على الدائرة ، فيجب أن تبدأ في تجميع سجلات التشخيص. يجب الإبلاغ عن ذلك من خلال شريط في الأعلى يقول ", _interpolate(_list(0)), " كما في الصورة:"])},
    "step5": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["يمكنك الآن إبطال ", _interpolate(_list(0)), " ، لأنه لم يعد هناك حاجة إليه."])},
    "step6": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["ستستغرق عملية جمع سجلات التشخيص عدة دقائق. انتظر 3-4 دقائق على الأقل. للتحقق من التقدم ارجع إلى ", _interpolate(_list(0)), " ، ثم إلى ", _interpolate(_list(1)), ". الآن قم بالتمرير لأسفل إلى ", _interpolate(_list(2)), " ، ثم ابحث عن ", _interpolate(_list(3)), "."])},
    "step7": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["الآن سترى على الأرجح الكثير من الإدخالات مرتبة أبجديًا. لا شيء يدعو للقلق ، هذه البيانات طبيعية. إذا قمت بالتمرير باتجاه الحرف ", _interpolate(_list(0)), " ورأيت إدخالات تبدأ بالاسم ", _interpolate(_list(1)), " ، فهذا يعني أن تجميع سجلات التشخيص جاري. انتظر بضع دقائق أخرى وحاول مرة أخرى."])},
    "step8": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["إذا كنت لا ترى إدخالًا يبدأ بالاسم ", _interpolate(_list(0)), " ، فانتقل إلى الحرف ", _interpolate(_list(1)), " ، وإذا رأيت إدخالًا يبدأ بـ ", _interpolate(_list(2)), " فهذا يعني أن تجميع سجلات التشخيص قد اكتمل بنجاح. قد يكون لديك عدة إدخالات هناك. يجب عليك اختيار الأحدث."])},
    "step9": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["إذا نقرت الآن على أحدث إدخال ", _interpolate(_list(0)), " ، فيمكنك حفظه باستخدام زر iOS الأساسي الموجود أعلى اليسار. ثم حدد ", _interpolate(_list(1)), " ، وحدد ", _interpolate(_list(2)), " وأخيرًا المجلد ", _interpolate(_list(3)), ". أنت الآن جاهز لتحميل سجلات التشخيص لتحليلها."])},
    "step10": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["يمكنك الآن الانتقال إلى ", _interpolate(_list(0)), " وتحديد الملف وإدخال عنوان بريدك الإلكتروني والتحميل."])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعدادات"])},
    "accessibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إمكانية الوصول"])},
    "touch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لمس. اتصال. صلة"])},
    "assistivetouch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اتصال المساعدة"])},
    "analytics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحليلات"])},
    "gathering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جمع التحليلات"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خصوصية"])},
    "analyticsimp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التحليلات والتحسينات"])},
    "analyticsdata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات التحليلات"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ في الملفات"])},
    "onmyiphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["على جهاز iPhone الخاص بي"])},
    "downloads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التحميلات"])},
    "doubletap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انقر نقرًا مزدوجًا"])}
  }
}