<template>
  <div class="text-center">
    <div class="mt-8">
      <div class="text-3xl font-bold">{{$t('waiting.waiting')}}</div>
      <div class="text-2xl">{{$t('waiting.holdon')}}</div>
      <div class="mt-4 text-8xl"><font-awesome-icon class="animate-spin" :icon="['fas', 'spinner']" /></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WaitingForResults',
  props: ['uuid'],
  created() {
    document.body.className = 'bg-gray-50';
  }
}
</script>
