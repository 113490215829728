<template>
  <div>
    <UploadForm />
  </div>
</template>

<script>
import UploadForm from '@/components/UploadForm.vue'

export default {
  name: 'HomePage',
  components: {
    UploadForm
  }
}
</script>
