<template>
  <div class="p-4">
    <div class="text-3xl font-bold mb-4">{{$t('iphone.iphone')}}</div>
    <i18n-t keypath="iphone.intro" tag="div">
      <b>{{$t('iphone.donot')}}</b>
    </i18n-t>

    <i18n-t keypath="iphone.intro2" tag="div" class="mt-2">
    </i18n-t>

    <i18n-t keypath="iphone.step1" tag="div" class="mt-8">
      <b>{{$t('iphone.settings')}}</b>
      <b>{{$t('iphone.accessibility')}}</b>
      <b>{{$t('iphone.touch')}}</b>
    </i18n-t>
    <div class="flex flex-wrap">
      <img src="@/assets/iphone/step1.png" class="border-6 rounded" />
      <img src="@/assets/iphone/step2.png" class="border-6 rounded" />
    </div>

    <i18n-t keypath="iphone.step2" tag="div" class="mt-8">
      <b>{{$t('iphone.touch')}}</b>
      <b>{{$t('iphone.assistivetouch')}}</b>
    </i18n-t>
    <img src="@/assets/iphone/step3.png" class="border-6 rounded" />

    <i18n-t keypath="iphone.step3" tag="div" class="mt-8">
      <b>{{$t('iphone.doubletap')}}</b>
      <b>{{$t('iphone.analytics')}}</b>
    </i18n-t>
    <img src="@/assets/iphone/step4.png" class="border-6 rounded" />

    <i18n-t keypath="iphone.step4" tag="div" class="mt-8">
      <b>{{$t('iphone.gathering')}}</b>
    </i18n-t>
    <img src="@/assets/iphone/step5.png" class="border-6 rounded" />

    <i18n-t keypath="iphone.step5" tag="div" class="mt-8">
      <b>{{$t('iphone.assistivetouch')}}</b>
    </i18n-t>

    <i18n-t keypath="iphone.step6" tag="div" class="mt-8">
      <b>{{$t('iphone.settings')}}</b>
      <b>{{$t('iphone.privacy')}}</b>
      <b>{{$t('iphone.analyticsimp')}}</b>
      <b>{{$t('iphone.analyticsdata')}}</b>
    </i18n-t>

    <div class="flex flex-wrap">
      <img src="@/assets/iphone/step6.png" class="border-6 rounded" />
      <img src="@/assets/iphone/step7.png" class="border-6 rounded" />
      <img src="@/assets/iphone/step8.png" class="border-6 rounded" />
    </div>

    <i18n-t keypath="iphone.step7" tag="div" class="mt-8">
      <b>i</b>
      <b>IN_PROGRESS_...</b>
    </i18n-t>
    <img src="@/assets/iphone/step9.png" class="border-6 rounded" />

    <i18n-t keypath="iphone.step8" tag="div" class="mt-8">
      <b>IN_PROGRESS_...</b>
      <b>s</b>
      <b>sysdiagnose_...</b>
    </i18n-t>
    <img src="@/assets/iphone/step10.png" class="border-6 rounded" />

    <i18n-t keypath="iphone.step9" tag="div" class="mt-8">
      <b>sysdiagnose</b>
      <b>{{$t('iphone.save')}}</b>
      <b>{{$t('iphone.onmyiphone')}}</b>
      <b>{{$t('iphone.downloads')}}</b>
      <b>sysdiagnose_...</b>
    </i18n-t>
    <div class="flex flex-wrap">
      <img src="@/assets/iphone/step11.png" class="border-6 rounded" />
      <img src="@/assets/iphone/step12.png" class="border-6 rounded" />
    </div>

    <i18n-t keypath="iphone.step10" tag="div" class="mt-8">
      <a class="underline hover:text-blue-400 text-blue-500" href="/">home of this platform</a>
    </i18n-t>
  </div>
</template>

<script>
export default {
  name: 'IPhoneGuidePage',
}
</script>
