<template>
  <div>
  </div>
</template>

<script>
export default {
  name: 'AndroidGuidePage',
}
</script>
