<template>
  <div v-if="failed" class="p-4">
    <div class="text-center mt-8">
      <div class="text-9xl"><font-awesome-icon :icon="['fas', 'frown']" /></div>
      <div class="text-3xl mt-4 front-bold">{{ $t('result.error') }}</div>
      <div class="text-2xl">{{ $t('result.error_text') }}</div>
      <div class="mt-8 text-2xl"><a href="/" class="underline hover:no-underline font-bold">{{ $t('result.return') }}</a></div>
    </div>
  </div>
  <div v-else>
    <!--
    ==================================================
      DETECTED
    ==================================================
    -->
    <div v-if="detected" class="text-white p-4">
      <div class="text-center mt-8">
        <div class="text-9xl"><font-awesome-icon :icon="['fas', 'viruses']" /></div>
        <div class="text-3xl mt-4 font-bold">{{ $t('result.warning') }}</div>
        <div class="text-2xl">{{ $t('result.infection') }}</div>
      </div>
      <div class="mt-8">
        <div><b>{{ $t('result.file') }}</b> {{this.file_name}}</div>
        <div><b>{{ $t('result.analysis') }}</b> {{this.uuid}}</div>
        <div><b>{{ $t('result.platform') }}</b> {{this.platform}}</div>
        <div class="mt-2">
          <div class="font-bold">{{ $t('result.detections') }}</div>
          <div class="break-words" v-for="ioc in indicators" :key="ioc.type">
            <i18n-t keypath="result.indicator" tag="p">
              <span>{{ioc.type}}</span>
              <b>{{ioc.name}}</b>
              <span>{{ioc.timestamp}}</span>
            </i18n-t>
          </div>
        </div>
      </div>
      <div class="mt-8">
        <i18n-t keypath="result.question" tag="div">
          <a class="font-bold underline hover:no-underline" :href="'mailto:' + this.contact">{{this.operator}}</a>
          <i>{{ $t('result.identifier') }}</i>
        </i18n-t>
      </div>
    </div>

    <!--
    ==================================================
      WARNING
    ==================================================
    -->
    <div v-else-if="warning" class="p-4">
      <div class="text-center mt-8">
        <div class="text-9xl"><font-awesome-icon :icon="['fa', 'triangle-exclamation']" /></div>
        <div class="text-3xl mt-4 font-bold">{{ $t('result.warning') }}</div>
      </div>
      <div class="mt-8">
        <div><b>{{ $t('result.file') }}</b> {{this.file_name}}</div>
        <div><b>{{ $t('result.analysis') }}</b> {{this.uuid}}</div>
        <div><b>{{ $t('result.platform') }}</b> {{this.platform}}</div>
      </div>
      <div class="mt-8">
      </div>
    </div>

    <!--
    ==================================================
      CLEAN
    ==================================================
    -->
    <div v-else class="text-white p-4">
      <div class="text-center mt-8">
        <div class="text-9xl"><font-awesome-icon :icon="['fas', 'check']" /></div>
        <div class="text-3xl mt-4 font-bold">{{ $t('result.no_trace') }}</div>
      </div>
      <div class="mt-8">
        <div><b>{{ $t('result.file') }}</b> {{this.file_name}}</div>
        <div><b>{{ $t('result.analysis') }}</b> {{this.uuid}}</div>
        <div><b>{{ $t('result.platform') }}</b> {{this.platform}}</div>
      </div>
      <div class="mt-8">
        <i18n-t keypath="result.warning_traces" tag="div">
          <b>{{ $t('result.warning') }}</b>
          <a class="font-bold underline hover:no-underline" :href="'mailto:' + this.contact">{{this.operator}}</a>
          <i>{{ $t('result.identifier') }}</i>
        </i18n-t>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require('axios').default;

export default {
  name: 'CheckResults',
  props: ['uuid'],
  data: function() {
    return {
      operator: process.env.VUE_APP_OPERATOR,
      contact: process.env.VUE_APP_CONTACT,
      detected: false,
      warning: false,
      failed: false,
      file_name: "",
      platform: "",
      indicators: [],
    }
  },
  methods: {
    getResults() {
      axios.get(process.env.VUE_APP_API_ENDPOINT + '/api/results/' + this.uuid + '/')
      .then(response => {
        this.file_name = response.data.file_name;
        this.platform = response.data.platform;
        if (response.data.status == 'failed') {
          // document.body.className = 'bg-yellow-500';
          this.failed = true;
          return;
        }

        if (response.data.matched_indicators.length > 0) {
          this.detected = true;
          this.indicators = response.data.matched_indicators;
          document.body.className = 'bg-red-500';
        } else {
          if (response.data.timeline_length > 0) {
            this.warning = true;
            document.body.className = 'bg-yellow-500';
          } else {
            document.body.className = 'bg-green-500';
          }
        }
      })
      .catch(err => {
        console.log("FAILED TO CHECK RESULTS:", err);
      })
    }
  },
  created() {
    this.getResults();
  }
}
</script>
