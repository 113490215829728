import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import '@/assets/css/tailwind.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { faViruses } from '@fortawesome/free-solid-svg-icons'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { faFrown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import i18n from "@/i18n";

library.add(faCheck);
library.add(faTriangleExclamation);
library.add(faViruses);
library.add(faSpinner);
library.add(faFrown);

createApp(App).use(i18n)
  .use(router)
  .use(i18n)
  .component('font-awesome-icon', FontAwesomeIcon)
  .mount('#app');
