<template>
  <div v-if="!completed">
    <WaitingForResults :uuid=$route.params.uuid />
  </div>
  <div v-else>
    <CheckResults :uuid=$route.params.uuid />
  </div>
</template>

<script>
const axios = require('axios').default;

import WaitingForResults from '@/components/WaitingForResults.vue'
import CheckResults from '@/components/CheckResults.vue'

export default {
  name: 'ResultsPage',
  data: function() {
    return {
      status: null,
      completed: false,
      checkInterval: null
    }
  },
  components: {
    WaitingForResults,
    CheckResults
  },
  methods: {
    isFinished() {
      if (this.status == 'completed' || this.status == 'failed')
        return true
      else
        return false
    },
    checkStatus() {
      console.log("Checking status for upload with UUID", this.$route.params.uuid);

      axios.get(process.env.VUE_APP_API_ENDPOINT + '/api/status/' + this.$route.params.uuid + '/')
      .then(response => {
        this.status = response.data.status;
        if (this.isFinished()) {
          clearInterval(this.checkInterval);
          this.completed = true;
        }
        console.log(this.status);
      })
      .catch(err => {
        console.log(err);
      })
    }
  },
  created() {
    this.checkStatus();
    if (!this.isFinished()) {
      this.checkInterval = setInterval(this.checkStatus, 10000);
    }
  }
}
</script>
