<template>
  <div class="p-4">
    <div class="text-center text-3xl font-bold">{{$t('confirmed.success')}}</div>
    <div class="mt-8">
      <div><b>{{$t('result.analysis')}}</b> {{this.uuid}}</div>
    </div>
    <div class="mt-4">
      <div>{{$t('confirmed.received')}}</div>
      <i18n-t keypath="result.question" tag="div">
        <a class="font-bold underline hover:no-underline" :href="'mailto:' + this.contact">{{this.operator}}</a>
        <i>{{$t('result.identifier')}}</i>
      </i18n-t>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConfirmedPage',
  data: function() {
    return {
      uuid: this.$route.params.uuid,
      operator: process.env.VUE_APP_OPERATOR,
      contact: process.env.VUE_APP_CONTACT,
    }
  },
  created() {
    document.body.className = 'bg-gray-50';
  }
}
</script>
